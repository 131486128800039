@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;600;700&display=swap');

html,
body,
#root,
.app,
.content{
  height: 100%;
  width: 100%;
  font-family: "Poppins" , sans-serif ;
}

.app{
  display: flex;
  position: relative;
}

::-webkit-scrollbar{
  width: 10px;
}
::-webkit-scrollbar-track{
  background: #e0e0e0;
}

::-webkit-scrollbar-thumb{
  background: #888;
}

::-webkit-scrollbar-track:hover{
  background:#555;
}

.inputField:focus{
  outline: rgb(26, 170, 218);
}

.imageUpload input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}